const {
  METHOD_EVOL_ELEC_PRICE,
  METHOD_EVOL_GAS_PRICE,
  BTES_COST,
  DEPARTMENT,
  SUBSIDIES: SUBSIDIES_TYPE,
  UNIT
} = require('../../constants');

const getBtesParams = (country, department) => {
  if (!BTES_COST[country]?.[department]) {
    country = 'FR';
    department = '01';
  }
  return {
    InitBtesCostA: {
      key: 'InitBtesCostA',
      type: 'number',
      min: 5,
      max: 200,
      default: BTES_COST[country][department].InitBtesCostA,
      department: DEPARTMENT[country][department].dep,
      unit: UNIT.EURO_PER_METER,
      required: true
    },
    InitBtesCostB: {
      key: 'InitBtesCostB',
      type: 'number',
      min: 0,
      max: 20000,
      default: BTES_COST[country][department].InitBtesCostB,
      department: DEPARTMENT[country][department].dep,
      unit: UNIT.EURO,
      required: true
    },
    InitBtesDiscountDict: {
      key: 'InitBtesDiscountDict',
      default: { 2000: 0, 5000: 6, 10000: 9, 20000: 10 },
      min: 0,
      max: 100,
      unit: UNIT.PERCENT
    }
  };
};

const HPG = Object.freeze({
  InitHPGCost: {
    key: 'InitHPGCost',
    default: { 0: 9100, 50: 364, 150: 227.5, 550: 127.4 },
    min: 0,
    unit: UNIT.EURO
  }
});

const HPA = Object.freeze({
  InitHPACostHeat: {
    key: 'InitHPACostHeat',
    default: { 0: 9425, 50: 377, 150: 236, 750: 132 },
    min: 0,
    unit: UNIT.EURO
  },
  InitHPACostHeatCold: {
    key: 'InitHPACostHeatCold',
    default: { 0: 11375, 50: 455, 150: 284.375, 750: 159.25 },
    min: 0,
    unit: UNIT.EURO
  }
});

const GAS = Object.freeze({
  InitCapexGas: {
    key: 'InitCapexGas',
    default: { 0: 5000, 50: 800, 200: 300, 750: 50 },
    min: 0,
    unit: UNIT.EURO
  }
});

const WATERTANK_HOT = Object.freeze({
  InitWaterTankHotCostA: {
    key: 'InitWaterTankHotCostA',
    type: 'number',
    min: 0,
    default: 700,
    unit: UNIT.EURO_PER_CUBIC_METER,
    required: true
  },
  InitWaterTankHotCostB: {
    key: 'InitWaterTankHotCostB',
    type: 'number',
    min: 0,
    default: 2000,
    unit: UNIT.EURO,
    required: true
  }
});

const WATERTANK_COLD = Object.freeze({
  InitWaterTankColdCostA: {
    key: 'InitWaterTankColdCostA',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO_PER_CUBIC_METER,
    required: true
  },
  InitWaterTankColdCostB: {
    key: 'InitWaterTankColdCostB',
    type: 'number',
    min: 0,
    default: 3000,
    unit: UNIT.EURO,
    required: true
  },
  InitWaterTankColdExchangerCostA: {
    key: 'InitWaterTankColdExchangerCostA',
    type: 'number',
    min: 0,
    default: 10,
    unit: UNIT.EURO_PER_KILOWATT,
    required: true
  },
  InitWaterTankColdExchangerCostB: {
    key: 'InitWaterTankColdExchangerCostB',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO,
    required: true
  },
  InitWaterTankColdPumpCostA: {
    key: 'InitWaterTankColdPumpCostA',
    type: 'number',
    min: 0,
    default: 100,
    unit: UNIT.EURO_PER_CUBIC_METER_PER_HOUR,
    required: true
  },
  InitWaterTankColdPumpCostB: {
    key: 'InitWaterTankColdPumpCostB',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO,
    required: true
  }
});

const WATERTANK_HOTWATER = Object.freeze({
  InitWaterTankHotwaterCostA: {
    key: 'InitWaterTankHotwaterCostA',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO_PER_CUBIC_METER,
    required: true
  },
  InitWaterTankHotwaterCostB: {
    key: 'InitWaterTankHotwaterCostB',
    type: 'number',
    min: 0,
    default: 3000,
    unit: UNIT.EURO,
    required: true
  },
  InitWaterTankHotwaterExchangerCostA: {
    key: 'InitWaterTankHotwaterExchangerCostA',
    type: 'number',
    min: 0,
    default: 10,
    unit: UNIT.EURO_PER_KILOWATT,
    required: true
  },
  InitWaterTankHotwaterExchangerCostB: {
    key: 'InitWaterTankHotwaterExchangerCostB',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO,
    required: true
  },
  InitWaterTankHotwaterPumpCostA: {
    key: 'InitWaterTankHotwaterPumpCostA',
    type: 'number',
    min: 0,
    default: 100,
    unit: UNIT.EURO_PER_CUBIC_METER_PER_HOUR,
    required: true
  },
  InitWaterTankHotwaterPumpCostB: {
    key: 'InitWaterTankHotwaterPumpCostB',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO,
    required: true
  }
});

const INJECTION = Object.freeze({
  InitInjectionExchangerCostA: {
    key: 'InitInjectionExchangerCostA',
    type: 'number',
    min: 0,
    default: 10,
    unit: UNIT.EURO_PER_KILOWATT,
    required: true
  },
  InitInjectionExchangerCostB: {
    key: 'InitInjectionExchangerCostB',
    type: 'number',
    min: 0,
    default: 1000,
    unit: UNIT.EURO,
    required: true
  },
  InitInjectionPumpCostA: {
    key: 'InitInjectionPumpCostA',
    type: 'number',
    min: 0,
    default: 20,
    unit: UNIT.EURO_PER_CUBIC_METER_PER_HOUR,
    required: true
  },
  InitInjectionPumpCostB: {
    key: 'InitInjectionPumpCostB',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO,
    required: true
  }
});

const SOLAR = Object.freeze({
  InitSolarThermalPanelCostA: {
    key: 'InitSolarThermalPanelCostA',
    type: 'number',
    min: 0,
    default: 350,
    unit: UNIT.EURO_PER_SQUARE_METER,
    required: true
  },
  InitSolarThermalVanneCost: {
    key: 'InitSolarThermalVanneCost',
    type: 'number',
    min: 0,
    default: 3000,
    unit: UNIT.EURO,
    required: true
  },
  InitSolarThermalPumpCostA: {
    key: 'InitSolarThermalPumpCostA',
    type: 'number',
    min: 0,
    default: 100,
    unit: UNIT.EURO_PER_CUBIC_METER_PER_HOUR,
    required: true
  },
  InitSolarThermalPumpCostB: {
    key: 'InitSolarThermalPumpCostB',
    type: 'number',
    min: 0,
    default: 1000,
    unit: UNIT.EURO,
    required: true
  }
});

const PV = Object.freeze({
  InitPVModuleCostA: {
    key: 'InitPVModuleCostA',
    type: 'number',
    min: 0,
    default: 56.1,
    unit: UNIT.EURO_PER_SQUARE_METER,
    required: true
  },
  InitPVModuleCostB: {
    key: 'InitPVModuleCostB',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO,
    required: true
  },
  InitPVModuleSupportCostA: {
    key: 'InitPVModuleSupportCostA',
    type: 'number',
    min: 0,
    default: 150.7,
    unit: UNIT.EURO_PER_SQUARE_METER,
    required: true
  },
  InitPVModuleSupportCostB: {
    key: 'InitPVModuleSupportCostB',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO,
    required: true
  },
  InitPVInverterCostA: {
    key: 'InitPVInverterCostA',
    type: 'number',
    min: 0,
    default: 82.5,
    unit: UNIT.EURO_PER_KILOWATT,
    required: true
  },
  InitPVInverterCostB: {
    key: 'InitPVInverterCostB',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO,
    required: true
  }
});

const ITES = Object.freeze({
  InitItesCostA: {
    key: 'InitItesCostA',
    type: 'number',
    min: 0,
    default: 30,
    unit: UNIT.EURO_PER_KILOWATT_THERMAL,
    required: true
  },
  InitItesCostB: {
    key: 'InitItesCostB',
    type: 'number',
    min: 0,
    default: 7500,
    unit: UNIT.EURO,
    required: true
  }
});

const HYDRAULIC = Object.freeze({
  InitCapexHydraulicMargin: {
    key: 'InitCapexHydraulicMargin',
    type: 'number',
    min: 0,
    max: 100,
    default: 0.2,
    unit: UNIT.PERCENT,
    required: true
  }
});

const ELEC_CAPEX = Object.freeze({
  InitCapexLotElecA: {
    key: 'InitCapexLotElecA',
    type: 'number',
    min: 0,
    default: 60,
    unit: UNIT.EURO_PER_KILOVOLT_AMPERE,
    required: true
  },
  InitCapexLotElecB: {
    key: 'InitCapexLotElecB',
    type: 'number',
    min: 0,
    default: 9000,
    unit: UNIT.EURO,
    required: true
  }
});

const ELEC_OPEX = Object.freeze({
  InitElecPriceEvolMethod: {
    key: 'InitElecPriceEvolMethod',
    type: 'string',
    default: METHOD_EVOL_ELEC_PRICE.MANUAL,
    values: Object.values(METHOD_EVOL_ELEC_PRICE),
    translateValuesIds: Object.values(METHOD_EVOL_ELEC_PRICE).map(
      (type) => `elecEvolMethod.${type}`
    ) // TODO côté front
  },
  InitElecPriceEvol: {
    key: 'InitElecPriceEvol',
    type: 'number',
    min: -1000,
    max: 1000,
    default: 3,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitPVElecToNetworkPriceEvol: {
    key: 'InitPVElecToNetworkPriceEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitPVElecToProjectPriceEvol: {
    key: 'InitPVElecToProjectPriceEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 3,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  }
});

const GAS_OPEX = Object.freeze({
  InitGasPriceEvolMethod: {
    key: 'InitGasPriceEvolMethod',
    type: 'string',
    default: METHOD_EVOL_GAS_PRICE.MANUAL,
    values: Object.values(METHOD_EVOL_GAS_PRICE),
    translateValuesIds: Object.values(METHOD_EVOL_GAS_PRICE).map(
      (type) => `gasEvolMethod.${type}`
    ) // TODO côté front
  },
  InitGasPriceEvol: {
    key: 'InitGasPriceEvol',
    type: 'number',
    min: -1000,
    max: 1000,
    default: 3,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitGasPriceSubscription: {
    key: 'InitGasPriceSubscription',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO,
    required: true
  },
  InitGasPrice: {
    key: 'InitGasPrice',
    type: 'number',
    min: 0,
    max: 0.3,
    default: 0.06,
    unit: UNIT.EURO_PER_KILOWATT_HOUR,
    required: true
  }
});

const OTHER_CAPEX = Object.freeze({
  InitMOEBtesMargin: {
    key: 'InitMOEBtesMargin',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitMOEMargin: {
    key: 'InitMOEMargin',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitIntegrationBtesMargin: {
    key: 'InitIntegrationBtesMargin',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitIntegrationMargin: {
    key: 'InitIntegrationMargin',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitCapexOther: {
    key: 'InitCapexOther',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO,
    required: true
  }
});

const SUBSIDIES = Object.freeze({
  InitSubventionVersion: {
    key: 'InitSubventionVersion',
    required: true,
    type: 'string',
    default: SUBSIDIES_TYPE.ADEME,
    values: Object.values(SUBSIDIES_TYPE),
    translateValuesIds: Object.values(SUBSIDIES_TYPE).map(
      (subsidiesType) => `subsidies.${subsidiesType}`
    ) // TODO côté front
  },
  InitSubventionMaxCapexPercent: {
    key: 'InitSubventionMaxCapexPercent',
    type: 'number',
    min: 0,
    max: 100,
    default: 45,
    unit: UNIT.PERCENT,
    required: true
  },
  InitSubventionAddOn: {
    key: 'InitSubventionAddOn',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.PERCENT,
    required: true
  },
  InitSubventionHeatENRSmall: {
    key: 'InitSubventionHeatENRSmall',
    type: 'number',
    min: 0,
    default: 50,
    unit: UNIT.EURO_PER_MEGAWATT_HOUR,
    required: true
  },
  InitSubventionColdENRSmall: {
    key: 'InitSubventionColdENRSmall',
    type: 'number',
    min: 0,
    default: 13,
    unit: UNIT.EURO_PER_MEGAWATT_HOUR,
    required: true
  },
  InitSubventionHeatENRLarge: {
    key: 'InitSubventionHeatENRLarge',
    type: 'number',
    min: 0,
    default: 40,
    unit: UNIT.EURO_PER_MEGAWATT_HOUR,
    required: true
  },
  InitSubventionColdENRLarge: {
    key: 'InitSubventionColdENRLarge',
    type: 'number',
    min: 0,
    default: 10,
    unit: UNIT.EURO_PER_MEGAWATT_HOUR,
    required: true
  }
});

const OTHER_OPEX_ACCENTA = Object.freeze({
  InitOpexGaPerf: {
    key: 'InitOpexGaPerf',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR,
    required: true
  },
  InitOpexGaPerfEvol: {
    key: 'InitOpexGaPerfEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitOpexMaint: {
    key: 'InitOpexMaint',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR,
    required: true
  },
  InitOpexMaintEvol: {
    key: 'InitOpexMaintEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitOpexOther: {
    key: 'InitOpexOther',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR,
    required: true
  },
  InitOpexOtherEvol: {
    key: 'InitOpexOtherEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitYearsBeforeStart: {
    key: 'InitYearsBeforeStart',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.YEAR,
    required: true
  }
});

const OTHER_OPEX_REF = Object.freeze({
  InitRefOpexGaPerf: {
    key: 'InitRefOpexGaPerf',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR,
    required: true
  },
  InitRefOpexGaPerfEvol: {
    key: 'InitRefOpexGaPerfEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitRefOpexMaint: {
    key: 'InitRefOpexMaint',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR,
    required: true
  },
  InitRefOpexMaintEvol: {
    key: 'InitRefOpexMaintEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  },
  InitRefOpexOther: {
    key: 'InitRefOpexOther',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR,
    required: true
  },
  InitRefOpexOtherEvol: {
    key: 'InitRefOpexOtherEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR,
    required: true
  }
});

const COST_PARAMS = [
  ...Object.values(getBtesParams()),
  ...Object.values(HPG),
  ...Object.values(HPA),
  ...Object.values(GAS),
  ...Object.values(WATERTANK_HOT),
  ...Object.values(WATERTANK_COLD),
  ...Object.values(WATERTANK_HOTWATER),
  ...Object.values(INJECTION),
  ...Object.values(SOLAR),
  ...Object.values(PV),
  ...Object.values(ITES),
  ...Object.values(HYDRAULIC),
  ...Object.values(ELEC_CAPEX),
  ...Object.values(OTHER_CAPEX),
  ...Object.values(SUBSIDIES),
  ...Object.values(ELEC_OPEX),
  ...Object.values(GAS_OPEX),
  ...Object.values(OTHER_OPEX_ACCENTA),
  ...Object.values(OTHER_OPEX_REF)
];

module.exports = {
  HPG,
  HPA,
  GAS,
  WATERTANK_HOT,
  WATERTANK_COLD,
  WATERTANK_HOTWATER,
  INJECTION,
  SOLAR,
  PV,
  ITES,
  HYDRAULIC,
  ELEC_CAPEX,
  OTHER_CAPEX,
  SUBSIDIES,
  ELEC_OPEX,
  GAS_OPEX,
  OTHER_OPEX_ACCENTA,
  OTHER_OPEX_REF,
  COST_PARAMS,
  getBtesParams
};
