import { useLingui } from '@lingui/react';
import React, { useContext, useRef, useState } from 'react';
import { NEED } from '../../../../../../../server/constants';
import { updateServicesSettings } from '../../../../../api/configSst.api';
import Bloc from '../../../../../components/Bloc/Bloc';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import { FORM_STATUS } from '../../../../../constants';
import ConfigsContext from '../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../contexts/PopupContext';
import { isArrNullOrEmpty } from '../../../../../utils/data.utils';
import ConfigFormPage from '../../../components/ConfigFormPage/ConfigFormPage';
import './SettingsPage.css';
import ColdSection from './sections/ColdSection';
import DhwSection from './sections/DhwSection/DhwSection';
import HeatSection from './sections/HeatSection';
import ServicesSection from './sections/ServicesSection/ServicesSection';

const SettingsPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config, setConfig, refreshConfig, modules } =
    useContext(ConfigsContext);
  const { openErrorToast, openConfirmModal } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [formStatus, setFormStatus] = useState(FORM_STATUS.ORIGIN);
  const [nbErrors, setNbErrors] = useState(0);
  //#endregion

  //#region [refs]
  const needsRef = useRef([...config.ConfigsSst[0].Data.services.needs]);
  //endregion

  //#region [methods]
  const addError = () => setNbErrors((prevErrors) => prevErrors + 1);

  const removeErrors = (value) =>
    setNbErrors((prevErrors) => prevErrors - value);

  const save = async () => {
    try {
      await updateServicesSettings(
        config.IsModular,
        config.ConfigsSst[0],
        modules
      );
      await refreshConfig();
    } catch (err) {
      throw err;
    }
  };

  const handleFormChange = (conf) => {
    setConfig(() => ({ ...conf }));
    setFormStatus(() => FORM_STATUS.DIRTY);
  };

  const handleSaveClick = async () => {
    try {
      setFormStatus(() => FORM_STATUS.SAVING);
      await save();
      setFormStatus(() => FORM_STATUS.SAVED);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
      setFormStatus(() => FORM_STATUS.DIRTY);
    }
  };
  //#region

  //#region [render]
  const { services, heatpumps } = config.ConfigsSst[0].Data;
  const { needs } = services;
  const formErrors = needs.length === 0 || nbErrors > 0;
  // on affiche une modal d'avertissement si le changement des usages impacte les PACs
  const modalWarning =
    !isArrNullOrEmpty(heatpumps.list) &&
    (needs.length < needsRef.current.length ||
      !needsRef.current.every((need) => needs.includes(need)));

  return (
    <ConfigFormPage formStatus={formStatus} formErrors={formErrors} save={save}>
      <Bloc title={i18n._('config.services.settings.title')}>
        <ServicesSection onFormChange={handleFormChange} />
        {needs.includes(NEED.HEAT) && (
          <HeatSection
            onFormChange={handleFormChange}
            addError={addError}
            removeErrors={removeErrors}
          />
        )}
        {needs.includes(NEED.COLD) && (
          <ColdSection
            onFormChange={handleFormChange}
            addError={addError}
            removeErrors={removeErrors}
          />
        )}
        {needs.includes(NEED.DHW) && (
          <DhwSection
            onFormChange={handleFormChange}
            addError={addError}
            removeErrors={removeErrors}
          />
        )}
      </Bloc>
      <SaveButton
        disabled={formErrors}
        formStatus={formStatus}
        onClick={
          !modalWarning
            ? () => handleSaveClick()
            : () =>
                openConfirmModal(
                  i18n._('config.services.settings.needsModal.title'),
                  i18n._('config.services.settings.needsModal.body'),
                  'warning',
                  () => handleSaveClick()
                )
        }
      />
    </ConfigFormPage>
  );
  //#endregion
};

export default SettingsPage;
